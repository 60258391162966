import React from "react";
import "../../styles/desktop/unknownError.css";
import "../../styles/mobile/unknownError.css";
import wumpus from "../../images/wumpus.svg";
import { Link } from "react-router-dom";

export interface UnknownErrorProps {
    error: string;
}

export function UnknownError({ error }: UnknownErrorProps) {
    console.log(`ERROR : ${Date.now() / 1000} -> ${error}`);

    return (
        <>
            <div className="unknown-error">
                <h1>An error occurred</h1>
                <p>Something unexpected happened...</p>
                <p style={{ fontSize: "16px" }}>Unix: {Date.now() / 1000}</p>
                <Link to="/">
                    <button>Go home</button>
                </Link>
            </div>
            <div className="wumpus">
                <img src={wumpus} alt="Wumpus" />
            </div>
        </>
    );
}
