export interface ReasonShortcut {
    guildid?: string;
    shortcut: string;
    reason?: string;
}

export async function getReasonShortcuts(
    guildid: string
): Promise<ReasonShortcut[]> {
    return fetch(`https://api.liege.dev/reasonshortcuts?guildid=${guildid}`, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => response.json());
}

export async function insertReasonShortcut(reasonShortcut: ReasonShortcut) {
    return fetch("https://api.liege.dev/reasonshortcuts", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reasonShortcut),
    });
}

export async function deleteReasonShortcut(reasonShortcut: ReasonShortcut) {
    return fetch("https://api.liege.dev/reasonshortcuts", {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reasonShortcut),
    });
}
