export interface Channel {
    id: string;
    name: string;
    position: number;
}

export interface Category {
    name: string;
    channels: Channel[];
}

/** Get channels in the provided guild */
export async function getChannels(guildId: string): Promise<Channel[]> {
    return (await fetch(
        `https://api.liege.dev/guilds/channels?guildid=${guildId}`,
        {
            method: "GET",
            credentials: "include",
        }
    ).then((response) => response.json())) as Channel[];
}

/** Get categories in the provided guild */
export async function getCategoriesWithChannels(
    guildId: string
): Promise<Category[]> {
    return (await fetch(
        `https://api.liege.dev/guilds/categories?guildid=${guildId}`,
        {
            method: "GET",
            credentials: "include",
        }
    ).then((response) => response.json())) as Category[];
}

/** Sets the provided channel to the provided endpoint
 * @param endpoint - https://api.liege.dev/settings/ + {endpoint}
 */
export async function setChannel(
    guildId: string,
    channelId: string,
    endpoint: string
) {
    await fetch("https://api.liege.dev/settings/channels/" + endpoint, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify({
            guildid: guildId,
            channelid: channelId,
        }),
    });
}
