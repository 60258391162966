import { Option, customStyles } from "../models/ReactSelect";
import { ToggleSwitch, ToggleSwitchProps } from "./ToggleSwitch";
import Select, { StylesConfig, GroupBase, OptionProps } from "react-select";
import "../styles/fonts.css";
import { getGuildIdFromUrl } from "../models/Guild";
import { useEffect, useState } from "react";
import {
    SettingsConfigJson,
    Times,
    AutoActionOptions,
    SpamOptions,
    SettingsType,
} from "../models/Settings";
import { getSetting, setSetting } from "../models/GuildSettings";
import {
    UseQueryResult,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

/** Props for the SettingsConfigCard component */
export interface SettingsConfigCardProps {
    onChange: (value: boolean) => void;
    settingsQuery: SettingsConfigJson | undefined;
    settingType: SettingsType;
    header: string;
    description: string;
    isEnabled?: boolean;
}

/** Component for the settings configuration card */
export function SettingsConfigCard({
    onChange,
    settingsQuery,
    settingType,
    header,
    description,
    isEnabled,
}: SettingsConfigCardProps) {
    const guildId = getGuildIdFromUrl();
    const queryClient = useQueryClient();

    const [isOn, setIsOn] = useState(settingsQuery?.config.isEnabled);

    useEffect(() => {
        if (settingsQuery) {
            setIsOn(settingsQuery.config.isEnabled);
        }
    }, [settingsQuery]);

    console.log(settingsQuery);

    const slowmode = Times.find(
        (time) =>
            time.value === settingsQuery?.config.slowmodeInterval.toString()
    );
    let action = AutoActionOptions.find(
        (x) => x.value === settingsQuery?.config.action.toString()
    );

    const toggleMutation = useMutation({
        mutationFn: (newSettings: SettingsConfigJson) =>
            setSetting(newSettings),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["settings", guildId] });
            onChange(!settingsQuery?.config.isEnabled);
        },
    });

    const handleToggle = () => {
        const newIsOn = !isOn;
        setIsOn(newIsOn);
        toggleMutation.mutate({
            guildid: guildId,
            setting: settingsQuery!.setting,
            config: {
                isEnabled: newIsOn,
                action: settingsQuery!.config.action.toString(),
                slowmodeInterval:
                    settingsQuery!.config.slowmodeInterval.toString(),
            },
        });
    };

    return (
        <div className="container">
            <div className="header">
                <h2>{header}</h2>
                {settingsQuery && settingType !== "raid" && (
                    <ToggleSwitch
                        isOn={isOn!}
                        handleToggle={() => handleToggle()}
                    />
                )}
            </div>
            <p>{description}</p>
            <div className="settings space-x-4">
                <div className="item">
                    <p>Action</p>
                    {settingsQuery ? (
                        <Select
                            className="select-setting"
                            isClearable={true}
                            isSearchable={true}
                            options={
                                settingsQuery.setting === "spam"
                                    ? SpamOptions
                                    : AutoActionOptions
                            }
                            styles={customStyles}
                            defaultValue={action}
                            onChange={(e) => {
                                if (e) {
                                    setSetting({
                                        guildid: guildId,
                                        setting: settingsQuery.setting,
                                        config: {
                                            isEnabled: isOn!,
                                            action: e.value as string,
                                            slowmodeInterval:
                                                settingsQuery.config.slowmodeInterval.toString(),
                                        },
                                    });
                                }
                            }}
                        />
                    ) : (
                        <div className="parent-skeleton">
                            <SkeletonTheme
                                baseColor="#2c2f33"
                                highlightColor="#222"
                            >
                                <Skeleton className="select-skeleton" />
                            </SkeletonTheme>
                        </div>
                    )}
                </div>
                <div className="item">
                    <p>Slowmode (seconds)</p>
                    {settingsQuery ? (
                        <div className="input-setting-parent">
                            <input
                                onBlur={(e) => {
                                    if (
                                        e.target.value ==
                                        settingsQuery.config.slowmodeInterval
                                    )
                                        return;
                                    setSetting({
                                        guildid: guildId,
                                        setting: settingsQuery.setting,
                                        config: {
                                            isEnabled: isOn!,
                                            action: settingsQuery.config.action.toString(),
                                            slowmodeInterval: e.target.value
                                                ? e.target.value
                                                : "0",
                                        },
                                    });
                                }}
                                type="number"
                                className="input-setting"
                                min="0"
                                max="21600"
                                defaultValue={
                                    settingsQuery.config.slowmodeInterval
                                }
                            />
                        </div>
                    ) : (
                        // <Select
                        //     className="select-setting"
                        //     isClearable={true}
                        //     isSearchable={true}
                        //     options={Times}
                        //     styles={customStyles}
                        //     defaultValue={slowmode}
                        //     onChange={(e) => {
                        //         if (e) {
                        //             setSetting({
                        //                 guildid: guildId,
                        //                 setting: settingsQuery.setting,
                        //                 config: {
                        //                     isEnabled: isOn!,
                        //                     action: settingsQuery.config.action.toString(),
                        //                     slowmodeInterval: e.value as string,
                        //                 },
                        //             });
                        //         }
                        //     }}
                        // />
                        <div className="parent-skeleton">
                            <SkeletonTheme
                                baseColor="#2c2f33"
                                highlightColor="#222"
                            >
                                <Skeleton className="select-skeleton" />
                            </SkeletonTheme>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
