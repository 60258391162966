import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getGuild, getGuildIdFromUrl } from "../models/Guild";
import "../styles/desktop/overview.css";
import "../styles/mobile/overview.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Logs } from "../comps/Logs";

export function Overview() {
    const guildid = getGuildIdFromUrl();
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: ["guild"],
        queryFn: () => getGuild(guildid),
    });

    return (
        <div className="overview">
            <h1>Overview</h1>
            {!query.isLoading || query.data ? (
                <div className="guild-parent">
                    <>
                        <img src={query.data?.icon} alt="guild icon" />
                        <div className="guild-info">
                            <h1>{query.data?.name}</h1>
                            <p>
                                Owner: {query.data?.owner}
                                &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; Members:{" "}
                                {query.data?.memberCount}
                            </p>
                        </div>
                    </>
                </div>
            ) : (
                <SkeletonTheme baseColor="#0f0f11" highlightColor="#222">
                    <Skeleton
                        height={"120px"}
                        width={"80%"}
                        borderRadius={"10px"}
                    />
                </SkeletonTheme>
            )}
            {/* <h1>Latest logs</h1>
            <Logs /> */}
        </div>
    );
}
