import { useEffect, useState } from "react";
import refresh from "../../images/refresh.svg";
import { getGuildIdFromUrl } from "../../models/Guild";
import "../../styles/desktop/moderation/cards/card.css";
import "../../styles/desktop/moderation/cards/shortcutCard.css";
import "../../styles/mobile/moderation/cards/card.css";
import "../../styles/mobile/moderation/cards/shortcutCard.css";
import {
    ReasonShortcut,
    getReasonShortcuts,
} from "../../models/ReasonShortcut";
import {
    AddShortcutsModal,
    RemoveShortcutsModal,
} from "../modals/ShortcutModals";
import { useQuery } from "@tanstack/react-query";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export function ShortcutCard() {
    const guildid = getGuildIdFromUrl();

    const [addModalOpen, setAddModalOpen] = useState(false);
    const [removeModalOpen, setRemoveModalOpen] = useState(false);

    const query = useQuery({
        queryKey: ["shortcuts"],
        queryFn: getReasonShortcuts.bind(null, guildid),
    });

    if (query.isLoading || !query.data) {
        return (
            <SkeletonTheme baseColor="#0f0f11" highlightColor="#222">
                <Skeleton className="card-skeleton" />
            </SkeletonTheme>
        );
    }
    const shortcuts = query.data as ReasonShortcut[];

    const handleRefresh = () => {
        query.refetch();
    };

    return (
        <div className="card">
            <div className="card-header space-x-8">
                <h2>Reason shortcuts</h2>
                <img
                    src={refresh}
                    height="25px"
                    width="25px"
                    onClick={handleRefresh}
                />
            </div>
            <div className="shortcuts space-x-2">
                <ul className="card-list">
                    {shortcuts.length !== 0 ? (
                        shortcuts?.map((shortcut) => (
                            <li className="card-list-item">
                                <span className="shortcut-name">
                                    {shortcut.shortcut}
                                </span>
                                <br />
                                {shortcut.reason}
                            </li>
                        ))
                    ) : (
                        <span>No shortcuts</span>
                    )}
                </ul>
            </div>
            <div className="card-buttons space-x-1">
                <button
                    className="card-button"
                    id="add"
                    onClick={() => setAddModalOpen(true)}
                >
                    Add shortcut
                </button>
                <button
                    className="card-button"
                    id="remove"
                    onClick={() => setRemoveModalOpen(true)}
                >
                    Remove shortcut
                </button>
            </div>
            {addModalOpen ? (
                <AddShortcutsModal
                    open={addModalOpen}
                    onClose={() => setAddModalOpen(false)}
                    refresh={handleRefresh}
                />
            ) : null}
            {removeModalOpen ? (
                <RemoveShortcutsModal
                    open={removeModalOpen}
                    onClose={() => setRemoveModalOpen(false)}
                    shortcuts={shortcuts!}
                    refresh={handleRefresh}
                />
            ) : null}
        </div>
    );
}
