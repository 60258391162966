/** Guild information necessary to create an option on the websites server selection page */
export interface Guild {
    id: string;
    name: string;
    icon?: string;
    permissions: string;
    inGuild: boolean;
    memberCount?: number;
    owner?: string;
}

/** Gets all the guilds that the user associated with the JWT cookie is in */
export async function getUserGuilds(): Promise<Guild[]> {
    return (await (
        await fetch("https://api.liege.dev/dashboard", {
            method: "GET",
            credentials: "include",
        }).then((res) => {
            if (res.status !== 200) {
                window.location.href = "/unknownerror";
            }
            return res;
        })
    ).json()) as Guild[];
}

/** Gets the provided guilds information
 * @returns {Promise<Guild>} The guild object
 */
export async function getGuild(guildId: string): Promise<Guild> {
    return (await await fetch(
        `https://api.liege.dev/guilds/guild?guildid=${guildId}`,
        {
            method: "GET",
            credentials: "include",
        }
    ).then((res) => {
        if (res.status !== 200) {
            window.location.href = "/notfound";
            return {} as Guild;
        }
        return res.json();
    })) as Guild;
}

/** Gets the guild object from the URL */
export function getGuildFromUrl(): Promise<Guild> {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("guildid")!;
    return getGuild(id);
}

/** Gets the guild id from the URL */
export function getGuildIdFromUrl(): string {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("guildid")!;
}
