import Select, { StylesConfig, GroupBase, OptionProps } from "react-select";
import {
    Option,
    customStyles,
    SelectChannelOption,
} from "../models/ReactSelect";
import {
    Category,
    getCategoriesWithChannels,
    setChannel,
} from "../models/Channel";
import { getGuildIdFromUrl } from "../models/Guild";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useQuery } from "@tanstack/react-query";
import { GuildSettings, getGuildSettings } from "../models/GuildSettings";

type channelOption = "modlog" | "eventlog";

/** The properties for the SelectChannel object */
export interface SelectChannelCardProps {
    name: string;
    description: string;
    endpoint: string;
    channelOption: channelOption;
}

/** Select menu for selecting any channel from a guild */
export function SelectChannelCard({
    name,
    description,
    endpoint,
    channelOption,
}: SelectChannelCardProps) {
    const guildid = getGuildIdFromUrl();

    const channelQuery = useQuery({
        queryKey: ["channels"],
        queryFn: () => getCategoriesWithChannels(guildid),
    });

    const settingsQuery = useQuery({
        queryKey: ["settings"],
        queryFn: () => getGuildSettings(guildid),
    });

    if (channelQuery.isLoading || !channelQuery.data) {
        return (
            <div className="parent-skeleton">
                <SkeletonTheme baseColor="#0f0f11" highlightColor="#222">
                    <Skeleton className="container-skeleton" />
                </SkeletonTheme>
            </div>
        );
    }
    if (settingsQuery.isLoading || !settingsQuery.data) {
        return (
            <div className="parent-skeleton">
                <SkeletonTheme baseColor="#0f0f11" highlightColor="#222">
                    <Skeleton className="container-skeleton" />
                </SkeletonTheme>
            </div>
        );
    }

    const categories = channelQuery.data as Category[];
    const guildSettings = settingsQuery.data as GuildSettings;

    const guildChannels: SelectChannelOption[] = [];
    for (const category of categories) {
        guildChannels.push({
            value: category.name,
            label: category.name,
            isDisabled: true,
        });
        for (const channel of category.channels) {
            guildChannels.push({
                value: channel.id,
                label: channel.name,
                isDisabled: false,
            });
        }
    }

    let defaultOptionId: string;
    switch (channelOption) {
        case "modlog":
            defaultOptionId =
                guildSettings?.channels?.modlogChannelId.toString();
            break;
        case "eventlog":
            defaultOptionId =
                guildSettings?.channels?.eventLogChannelId.toString();
            break;
    }

    const currentChannel = guildChannels.find(
        (x) => x.value.toString() === defaultOptionId
    )!;

    return (
        <div className="select">
            {defaultOptionId !== undefined && (
                <div className="select-container" id={channelOption}>
                    <h1>{name}</h1>
                    <Select
                        className="select-channel"
                        isClearable={true}
                        isSearchable={true}
                        name={name}
                        options={guildChannels}
                        styles={customStyles}
                        defaultValue={currentChannel}
                        onChange={(e) => {
                            if (e) {
                                setChannel(
                                    guildid,
                                    e.value.toString(),
                                    endpoint
                                );
                            }
                        }}
                    />
                    <p>{description}</p>
                </div>
            )}
        </div>
    );
}
