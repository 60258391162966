import { useState } from "react";
import { useEffect } from "react";
import noIconImage from "../images/noicon.svg";
import { Guild, getUserGuilds } from "../models/Guild";
import "../styles/desktop/dashboard.css";
import "../styles/mobile/dashboard.css";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import refresh from "../images/refresh.svg";
import { getEnvironmentData } from "worker_threads";

/** The dashboard page that shows server selection */
export function Dashboard() {
    const query = useQuery({ queryKey: ["guilds"], queryFn: getUserGuilds });

    const handleRefresh = () => {
        query.refetch();
    };

    return (
        <div className="guilds">
            <div className="guilds-header">
                <h1 id="servers">Manage your servers</h1>{" "}
                <img
                    src={refresh}
                    height="30px"
                    width="30px"
                    onClick={handleRefresh}
                />
            </div>
            <div className="in-guilds">
                {!query.isLoading || query.data ? (
                    (query.data! as Guild[])
                        .filter((guild) => guild.inGuild)
                        .map((inGuild) => <GuildCard guild={inGuild} />)
                ) : (
                    <SkeletonTheme baseColor="#0e0e0e" highlightColor="#222">
                        <Skeleton
                            containerClassName="skeleton-item flex-1"
                            className="skeleton-item"
                        />
                        <Skeleton
                            containerClassName="skeleton-item flex-1"
                            className="skeleton-item"
                        />

                        <Skeleton
                            containerClassName="skeleton-item flex-1"
                            className="skeleton-item"
                        />
                    </SkeletonTheme>
                )}
            </div>
            <div className="guilds-header">
                <h1 id="servers">Invite TED to your server</h1>
                <img
                    src={refresh}
                    height="30px"
                    width="30px"
                    onClick={handleRefresh}
                />
            </div>{" "}
            <div className="not-in-guilds">
                {!query.isLoading || query.data ? (
                    (query.data! as Guild[])
                        .filter((guild) => !guild.inGuild)
                        .map((notInGuild) => <GuildCard guild={notInGuild} />)
                ) : (
                    <SkeletonTheme baseColor="#0e0e0e" highlightColor="#222">
                        <Skeleton
                            containerClassName="skeleton-item flex-1"
                            className="skeleton-item"
                        />
                        <Skeleton
                            containerClassName="skeleton-item flex-1"
                            className="skeleton-item"
                        />

                        <Skeleton
                            containerClassName="skeleton-item flex-1"
                            className="skeleton-item"
                        />
                    </SkeletonTheme>
                )}
            </div>
        </div>
    );
}

function GuildCard({ guild }: { guild: Guild }) {
    return (
        <div className="guild-card">
            <div className="card-container">
                <img
                    src={guild.icon === "_" ? noIconImage : guild.icon}
                    alt={guild.name}
                    width="112px"
                    height="112px"
                />
                <div className="text-button">
                    <h1>{guild.name}</h1>
                    {!guild.inGuild ? (
                        <Link
                            to={`https://discord.com/oauth2/authorize?client_id=879360985738117120&permissions=1507532729591&scope=bot&guild_id=${guild.id}`}
                        >
                            <button>Invite TED</button>
                        </Link>
                    ) : (
                        <Link
                            to={`http://ted.liege.dev/dashboard/overview?guildid=${guild.id}`}
                        >
                            <button>Manage</button>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
}
