import "../styles/desktop/navbar.css";
import "../styles/mobile/navbar.css";
import "../styles/desktop/body.css";
import logo from "../images/ted.png";
import hamburger from "../images/hamburger.svg";
import close from "../images/x.svg";
import premium from "../images/premium.svg";
import { useEffect, useState } from "react";
import { User, getUser } from "../models/User";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

/** Global navbar component */
export function Navbar() {
    const [hamburgerClosed, setHamburgerClosed] = useState(true);

    const query = useQuery({
        queryKey: ["user"],
        queryFn: () => getUser(),
    });

    const user = query.data as User;

    // go to home page
    const homepage = () => {
        window.location.href = "/";
    };

    return (
        <div
            className={`navbar ${
                hamburgerClosed ? `navbar-closed` : `navbar-open`
            }`}
        >
            <div className="dynamic-nav">
                <div
                    className="brand"
                    onClick={() => homepage()}
                    style={{ cursor: "pointer" }}
                >
                    <img src={logo} width="40px" height="40px" />
                    <p>TED</p>
                </div>
                <div className="right">
                    <div className="icon-container">
                        <a
                            className="premium-button"
                            href="https://premium.liege.dev"
                            target="_blank"
                            id="premium-mobile"
                        >
                            <img src={premium} width="34px" />
                        </a>
                        <img
                            className="close"
                            src={close}
                            width="32px"
                            height="32px"
                            onClick={() => setHamburgerClosed(!hamburgerClosed)}
                        ></img>
                        <img
                            className="hamburger"
                            src={hamburger}
                            width="30px"
                            height="30px"
                            onClick={() => setHamburgerClosed(!hamburgerClosed)}
                        ></img>
                    </div>
                    <div className="user">
                        <img
                            src={user && user.avatar_url}
                            width="32px"
                            height="32px"
                        ></img>
                    </div>
                </div>
            </div>
            <div className="navbar-container">
                <div className="list">
                    <ul>
                        <li>
                            {user ? (
                                <Link to="guilds">Dashboard</Link>
                            ) : (
                                <a
                                    href={`https://discord.com/oauth2/authorize?client_id=879360985738117120&response_type=code&redirect_uri=https%3A%2F%2Fapi.liege.dev%2Foauth&scope=guilds+identify`}
                                    // href={`https://discord.com/oauth2/authorize?client_id=879360985738117120&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3001%2Foauth&scope=identify+guilds`}
                                >
                                    Dashboard
                                </a>
                            )}
                        </li>
                        <li>
                            <Link to="https://docs.liege.dev" target="_blank">
                                Documentation
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="https://docs.liege.dev/api/introduction"
                                target="_blank"
                            >
                                API
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="#about">About</Link>
                        </li> */}
                    </ul>
                </div>
                <div className="divider-parent">
                    <div className="divider"></div>
                </div>
                {!user ? (
                    // <a href="https://discord.com/oauth2/authorize?client_id=879360985738117120&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3001%2Foauth&scope=guilds+identify">
                    <a
                        href={`https://discord.com/oauth2/authorize?client_id=879360985738117120&response_type=code&redirect_uri=https%3A%2F%2Fapi.liege.dev%2Foauth&scope=guilds+identify`}
                    >
                        <button className="signin">Sign in</button>
                    </a>
                ) : (
                    <div className="navbar-user">
                        {/* <img
                                src={user.avatar_url}
                                width="32px"
                                height="32px"
                            />
                            <p>{user.username}</p> */}
                        <div className="buttons">
                            <a
                                className="premium-button"
                                href="https://premium.liege.dev"
                                target="_blank"
                                id="premium-desktop"
                            >
                                <img src={premium} width="24px" height="24px" />
                                Premium
                            </a>
                            <Link to="https://api.liege.dev/oauth/logout">
                                <button className="signout">Sign out</button>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
