import { CensorCard } from "../comps/moderation/CensorCard";
import { RegexCard } from "../comps/moderation/RegexCard";
import { ShortcutCard } from "../comps/moderation/ShortcutCard";
import "../styles/desktop/moderation/moderation.css";
import "../styles/mobile/moderation/moderation.css";

export function Moderation() {
    return (
        <div className="moderation">
            <h1>Moderation</h1>
            <div className="cards space-y-8">
                <CensorCard />
                <RegexCard />
                <ShortcutCard />
            </div>
        </div>
    );
}
