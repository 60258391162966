export interface Regex {
    guildid?: string;
    statement: string;
    action?: number;
}

export async function getRegexs(guildid: string): Promise<Regex[]> {
    return fetch(`https://api.liege.dev/regex?guildid=${guildid}`, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((response) => response.json());
}

export async function insertRegex(regex: Regex) {
    return fetch("https://api.liege.dev/regex", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(regex),
    });
}

/** Do not provide 'action' param! */
export async function deleteRegex(regex: Regex) {
    return fetch("https://api.liege.dev/regex", {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(regex),
    });
}
