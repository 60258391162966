import { SettingsConfigJson, SettingsType } from "./Settings";

/** An object that is identical to the database guild object */
export interface GuildSettings {
    guildid: string;
    lastUpdated: string;
    isBanned: boolean;
    // isPremium: boolean;
    permissions: Permissions;
    settings: Settings;
    channels: Channels;
    ignored: Ignored;
}

interface Permissions {
    roles: string[];
    users: string[];
}

interface SettingConfig {
    isEnabled: boolean;
    action: number;
    slowmodeInterval: number;
}

interface Settings {
    dupeDetection: SettingConfig;
    inviteDetection: SettingConfig;
    scamDetection: SettingConfig;
    spamDetection: SettingConfig;
    doxDetection: SettingConfig;
    aiDetection: SettingConfig;
    raidDetection: SettingConfig;
}

interface Channels {
    modlogChannelId: string;
    eventLogChannelId: string;
}

interface Ignored {
    channels: string[];
    roles: string[];
    users: string[];
}

/** Gets the guild settings from the provided guild id
 * @returns {Promise<GuildSettings>} The guild settings object
 */
export async function getGuildSettings(
    guildId: string
): Promise<GuildSettings> {
    return (await (
        await fetch(`https://api.liege.dev/settings/all?guildid=${guildId}`, {
            method: "GET",
            credentials: "include",
        })
    ).json()) as GuildSettings;
}

/** Get specified setting for specified guild */
export async function getSetting(setting: SettingsType, guildId: string) {
    return (await (
        await fetch(
            `https://api.liege.dev/settings/setting?guildid=${guildId}&setting=${setting}`,
            {
                method: "GET",
                credentials: "include",
            }
        )
    ).json()) as SettingsConfigJson;
}

export async function getSettings(guildId: string) {
    return (await (
        await fetch(
            `https://api.liege.dev/settings/settings?guildid=${guildId}`,
            {
                method: "GET",
                credentials: "include",
            }
        )
    ).json()) as SettingsConfigJson[];
}

/*
 * Set specified setting for specified guild
 */
// export async function setSetting(
//     guildId: string,
//     newSettings: SettingsConfigJson
// ): Promise<SettingsConfigJson> {
//     return await fetch(
//         `https://api.liege.dev/api/settings/setsetting?guildid=${guildId}`,
//         {
//             method: "POST",
//             credentials: "include",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(newSettings),
//         }
//     ).then((res) => res.json() as Promise<SettingsConfigJson>);
// }

export const setSetting = (
    newSettings: SettingsConfigJson
): Promise<Response> => {
    return fetch(`https://api.liege.dev/settings/setting`, {
        method: "PATCH",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(newSettings),
    });
};
