import "../styles/desktop/section.css";
import "../styles/mobile/section.css";
import { Button, ButtonProps } from "./Button";

/** Properties for the section component */
interface SectionProps {
    title: string;
    description: string;
    img: [string, string];
    imgRight: boolean;
    buttons?: ButtonProps[];
    id: string;
    firstWord?: string;
    lastWord?: string;
}

/**
 * Section component; typically used for the landing page;
 * has header over text next to an image with optional buttons
 */
export function Section(props: SectionProps) {
    return (
        <div
            id={props.id}
            className={`section-${props.imgRight ? `right` : `left`}`}
        >
            <div className="sec-text">
                <h1>
                    {props.firstWord ? <span>{props.firstWord}</span> : null}{" "}
                    {props.title}{" "}
                    {props.lastWord ? <span> {props.lastWord}</span> : null}
                </h1>
                <p key="end">{props.description}</p>
                {props.buttons?.length !== 0 ? (
                    <div className="sec-buttons">
                        {props.buttons?.map((button) => (
                            <Button
                                key={button.text}
                                text={button.text}
                                link={button.link}
                                color={button.color}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
            <div className="sec-image" id={props.img[1]}>
                <img src={props.img[0]} />
            </div>
        </div>
    );
}
