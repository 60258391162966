export enum AutoAction {
    Delete = 0,
    Warn = 1,
    Timeout30m = 2,
    Timeout6h = 3,
    TimeoutIndefinitely = 4,
    Kick = 5,
    Ban7d = 6,
    BanPerm = 7,
}
