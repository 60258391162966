/** User object that returns information that is displayed on the website */
export interface User {
    user_id: string;
    username: string;
    avatar_url: string;
}

/** Get the user object for the user provided in the OAuth process */
export async function getUser(): Promise<User | undefined> {
    let res = await fetch("https://api.liege.dev/oauth/user", {
        method: "GET",
        credentials: "include",
    });

    if (res.status === 200) {
        return res.json() as Promise<User>;
    } else {
        return undefined;
    }
}
