import React, { useEffect, useState } from "react";
import "../../styles/desktop/moderation/modals/censorModals.css";
import { TagsInput } from "react-tag-input-component";
import ReactSelect from "react-select";
import { AutoActionOptions } from "../../models/Settings";
import { MultiCustomStyles, customStyles } from "../../models/ReactSelect";
import xImage from "../../images/x.svg";
import "../../styles/desktop/moderation/modals/modal.css";
import "../../styles/mobile/moderation/modals/censorModals.css";
import { Censor, removeCensors, insertCensors } from "../../models/Censor";
import { getGuildIdFromUrl } from "../../models/Guild";

export interface AddCensorModalProps {
    open: boolean;
    onClose: () => void;
    refresh: () => void;
}

export function AddCensorsModal({
    open,
    onClose,
    refresh,
}: AddCensorModalProps) {
    const [tags, setTags] = React.useState<string[]>([]);

    return open ? (
        <div>
            <div className="modal-parent">
                <div className="modal space-y-4">
                    <div className="modal-header">
                        <h1>Add censor(s)</h1>
                        <img src={xImage} alt="Close" onClick={onClose} />
                    </div>
                    <TagsInput
                        value={tags}
                        onChange={setTags}
                        name="tags"
                        placeHolder="Add word"
                    />
                    <ReactSelect
                        options={AutoActionOptions}
                        styles={customStyles}
                        defaultValue={AutoActionOptions[0]}
                        isClearable={true}
                    />
                    <button
                        id="submit"
                        type="submit"
                        onClick={() => {
                            if (tags.length === 0) onClose();
                            insertCensors({
                                guildid: getGuildIdFromUrl(),
                                terms: tags,
                                action: 0,
                            }).catch((err) => console.error(err));
                            refresh();
                            onClose();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    ) : null;
}

export interface RemoveCensorModalProps {
    open: boolean;
    onClose: () => void;
    censors: Censor[];
    refresh: () => void;
}

export function RemoveCensorsModal({
    open,
    onClose,
    censors,
    refresh,
}: RemoveCensorModalProps) {
    const deleteCensors = censors
        ?.filter((censor) => censor.action === 0)
        .map((censor) => censor.term);
    const warnCensors = censors
        ?.filter((censor) => censor.action === 1)
        .map((censor) => censor.term);
    const timeout30mCensors = censors
        ?.filter((censor) => censor.action === 2)
        .map((censor) => censor.term);
    const timeout6hCensors = censors
        ?.filter((censor) => censor.action === 3)
        .map((censor) => censor.term);
    const timeoutCensors = censors
        ?.filter((censor) => censor.action === 4)
        .map((censor) => censor.term);
    const kickCensors = censors
        ?.filter((censor) => censor.action === 5)
        .map((censor) => censor.term);
    const ban7dCensors = censors
        ?.filter((censor) => censor.action === 6)
        .map((censor) => censor.term);
    const banCensors = censors
        ?.filter((censor) => censor.action === 7)
        .map((censor) => censor.term);

    const [selectedDeleteCensors, setSelectedDeleteCensors] =
        useState<string[]>();
    const [selectedWarnCensors, setSelectedWarnCensors] = useState<string[]>();
    const [selectedTimeout30mCensors, setSelectedTimeout30mCensors] =
        useState<string[]>();
    const [selectedTimeout6hCensors, setSelectedTimeout6hCensors] =
        useState<string[]>();
    const [selectedTimeoutCensors, setSelectedTimeoutCensors] =
        useState<string[]>();
    const [selectedKickCensors, setSelectedKickCensors] = useState<string[]>();
    const [selectedBan7dCensors, setSelectedBan7dCensors] =
        useState<string[]>();
    const [selectedBanCensors, setSelectedBanCensors] = useState<string[]>();

    const censorsExist = !(
        deleteCensors.length === 0 &&
        warnCensors.length === 0 &&
        timeout30mCensors.length === 0 &&
        timeout6hCensors.length === 0 &&
        timeoutCensors.length === 0 &&
        kickCensors.length === 0 &&
        ban7dCensors.length === 0 &&
        banCensors.length === 0
    );

    return open ? (
        <div>
            <div className="modal-parent">
                <div className="modal space-y-4">
                    <div className="modal-header">
                        <h1>Remove censor(s)</h1>
                        <img src={xImage} alt="Close" onClick={onClose} />
                    </div>
                    {censorsExist ? (
                        <>
                            {deleteCensors && deleteCensors.length > 0 ? (
                                <DeleteSelector
                                    name="Delete"
                                    options={deleteCensors}
                                    setSelected={setSelectedDeleteCensors}
                                />
                            ) : null}
                            {warnCensors && warnCensors.length > 0 ? (
                                <DeleteSelector
                                    name="Warn"
                                    options={warnCensors}
                                    setSelected={setSelectedWarnCensors}
                                />
                            ) : null}
                            {timeout30mCensors &&
                            timeout30mCensors.length > 0 ? (
                                <DeleteSelector
                                    name="Timeout 30m"
                                    options={timeout30mCensors}
                                    setSelected={setSelectedTimeout30mCensors}
                                />
                            ) : null}
                            {timeout6hCensors && timeout6hCensors.length > 0 ? (
                                <DeleteSelector
                                    name="Timeout 6h"
                                    options={timeout6hCensors}
                                    setSelected={setSelectedTimeout6hCensors}
                                />
                            ) : null}
                            {timeoutCensors && timeoutCensors.length > 0 ? (
                                <DeleteSelector
                                    name="Timeout"
                                    options={timeoutCensors}
                                    setSelected={setSelectedTimeoutCensors}
                                />
                            ) : null}
                            {kickCensors && kickCensors.length > 0 ? (
                                <DeleteSelector
                                    name="Kick"
                                    options={kickCensors}
                                    setSelected={setSelectedKickCensors}
                                />
                            ) : null}
                            {ban7dCensors && ban7dCensors.length > 0 ? (
                                <DeleteSelector
                                    name="Ban 7d"
                                    options={ban7dCensors}
                                    setSelected={setSelectedBan7dCensors}
                                />
                            ) : null}
                            {banCensors && banCensors.length > 0 ? (
                                <DeleteSelector
                                    name="Ban perm"
                                    options={banCensors}
                                    setSelected={setSelectedBanCensors}
                                />
                            ) : null}
                            <button
                                type="submit"
                                id="submit"
                                onClick={() => {
                                    const tags = selectedDeleteCensors
                                        ?.concat(selectedWarnCensors ?? [])
                                        .concat(selectedTimeout30mCensors ?? [])
                                        .concat(selectedTimeout6hCensors ?? [])
                                        .concat(selectedTimeoutCensors ?? [])
                                        .concat(selectedKickCensors ?? [])
                                        .concat(selectedBan7dCensors ?? [])
                                        .concat(selectedBanCensors ?? []);
                                    if (tags !== undefined && tags.length < 1)
                                        onClose();
                                    removeCensors({
                                        guildid: getGuildIdFromUrl(),
                                        terms: tags!,
                                    }).catch((err) => console.error(err));
                                    refresh();
                                    onClose();
                                }}
                            >
                                Submit
                            </button>
                        </>
                    ) : (
                        <>
                            <p>There are no censors to remove!</p>
                            <button
                                id="submit"
                                style={{ backgroundColor: "#36393f" }}
                                onClick={onClose}
                            >
                                Close
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    ) : null;
}

interface DeleteSelectorProps {
    name: string;
    options: string[];
    setSelected: (x: string[]) => void;
}

function DeleteSelector({ name, options, setSelected }: DeleteSelectorProps) {
    return (
        <div className="selector">
            <h3>{name}</h3>
            <ReactSelect
                options={options.map((option) => ({
                    value: option,
                    label: option,
                }))}
                isMulti={true}
                styles={MultiCustomStyles}
                isClearable={true}
                closeMenuOnSelect={false}
                placeholder="Select..."
                onChange={(x) => {
                    setSelected(x.map((y) => y.value));
                }}
            />
        </div>
    );
}
