import { StylesConfig } from "react-select";

/** Object is used for select menus values and labels */
export interface Option {
    value: string;
    label: string;
}

/** Option object for each channel in the channel select menu
 * @param isDisabled - Set to true if the channel is a category
 */
export interface SelectChannelOption {
    value: string;
    label: string;
    isDisabled: boolean;
}

export const customStyles: StylesConfig<Option, false> = {
    control: (provided, state) => ({
        ...provided,
        width: "100%",
        backgroundColor: "#2C2F33",
        borderColor: "#2C2F33",
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#2C2F33",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#18191C" : "#2C2F33",
        color: state.isDisabled ? "white" : "#DDDDDD",
        fontWeight: state.isDisabled ? 400 : 200,
        font: "Inter",
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: "white",
        fontWeight: 200,
    }),
    input: (provided) => ({
        ...provided,
        color: "white",
        fontWeight: 200,
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        color: "white",
        cursor: "pointer",
        transition: "color 0.2s",
        "&:hover": {
            color: "white",
        },
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: "white",
        cursor: "pointer",
        transition: "color 0.2s",
        "&:hover": {
            color: "white",
        },
    }),
};

export const MultiCustomStyles: StylesConfig<Option, true> = {
    control: (provided, state) => ({
        ...provided,
        width: "100%",
        backgroundColor: "#2C2F33",
        borderColor: "#2C2F33",
        margin: "0",
        padding: "0",
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#2C2F33",
        margin: "0",
        padding: "0",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#18191C" : "#2C2F33",
        color: state.isDisabled ? "white" : "#DDDDDD",
        font: "Inter",
    }),
    input: (provided) => ({
        ...provided,
        color: "white",
        fontWeight: 200,
        padding: "3px 0 !important",
    }),
    placeholder: (styles) => ({
        ...styles,
        fontWeight: 200,
        margin: "0",
        padding: "3px 0 !important",
        position: "relative",
    }),
    container: (styles) => ({
        ...styles,
    }),
    valueContainer: (styles) => ({
        ...styles,
        padding: "6px 8px",
        display: "flex",
        gap: "8px",
    }),
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: "#747F8D",
            borderRadius: "5px",
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: 200,
            padding: "1px 5px",
            margin: "0",
        };
    },
    multiValueLabel: (styles) => ({
        ...styles,
        color: "white",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 200,
        padding: "2px 0px",
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: "white",
        ":hover": {
            color: "red",
        },
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        color: "white",
        cursor: "pointer",
        transition: "color 0.2s",
        "&:hover": {
            color: "white",
        },
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: "white",
        cursor: "pointer",
        transition: "color 0.2s",
        "&:hover": {
            color: "white",
        },
    }),
};
