import React from "react";
import "../../styles/desktop/notFound.css";
import "../../styles/mobile/notFound.css";
import wumpus from "../../images/wumpus.svg";
import { Link } from "react-router-dom";

export function NotFound() {
    return (
        <>
            <div className="not-found">
                <h1>Not Found</h1>
                <p>The page you are looking for does not exist.</p>
                <Link to="/">
                    <button>Go home</button>
                </Link>
            </div>
            <div className="wumpus">
                <img src={wumpus} alt="Wumpus" />
            </div>
        </>
    );
}
