import { useEffect, useState } from "react";
import "../styles/desktop/sidebar.css";
import "../styles/mobile/sidebar.css";
import overview from "../images/overview.svg";
import logging from "../images/logging.svg";
import autoModeration from "../images/automod.svg";
import arrow from "../images/arrow.svg";
import moderation from "../images/moderation.svg";
import {
    Guild,
    getGuild,
    getGuildFromUrl,
    getGuildIdFromUrl,
} from "../models/Guild";
import { Link } from "react-router-dom";

/** Sidebar component used for functional dashboard */
export function GuildManageSidebar() {
    const [guildid, setGuildId] = useState<string>(getGuildIdFromUrl());

    const [isFolded, setIsFolded] = useState<boolean>(true);

    const [guild, setGuild] = useState<Guild>();
    useEffect(() => {
        getGuild(guildid).then((guild) => {
            setGuild(guild);
        });
    }, [guildid]);

    return (
        <div className={`sidebar-parent ${isFolded ? "folded" : "notfolded"}`}>
            {guild !== undefined ? (
                <div className="sidebar">
                    <div className="server">
                        <div className="server-info">
                            <img src={guild?.icon}></img>
                            <h1>{guild?.name}</h1>
                            {guild?.inGuild === false && (
                                <a>
                                    <button className="invite">
                                        Invite to Server
                                    </button>
                                </a>
                            )}
                        </div>
                        <div
                            className="list-dropdown"
                            onClick={() => setIsFolded(!isFolded)}
                        >
                            <img src={arrow}></img>
                        </div>
                    </div>
                    <div className="sidebar-container">
                        <ul className={guild?.inGuild ? `enabled` : `disabled`}>
                            <li>
                                <Link
                                    to={`/dashboard/overview?guildid=${guild?.id}`}
                                >
                                    <img src={overview} />
                                    <span className="">Overview</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`/dashboard/logging?guildid=${guild?.id}`}
                                >
                                    <img src={logging} />
                                    Logging
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`/dashboard/automoderation?guildid=${guild?.id}`}
                                >
                                    <img src={autoModeration} />
                                    AutoModeration
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`/dashboard/moderation?guildid=${guild?.id}`}
                                >
                                    <img src={moderation} />
                                    Moderation
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            ) : (
                <div>{/* <h1>Loading...</h1> */}</div>
            )}
        </div>
    );
}
