import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import "./index.css";
import { Home } from "./pages/Home";
import { Dashboard } from "./pages/Dashboard";
import { Navbar } from "./comps/Navbar";
import { Footer } from "./comps/Footer";
import { GuildManageSidebar } from "./comps/Sidebar";
import { NotFound } from "./pages/errors/NotFound";
import { Unauthorized } from "./pages/Unauthorized";
import { Overview } from "./pages/Overview";
import { Logging } from "./pages/Logging";
import { Automoderation } from "./pages/Automoderation";
import { Moderation } from "./pages/Moderation";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { UnknownError } from "./pages/errors/UnknownError";
import { ErrorBoundary } from "react-error-boundary";

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <ErrorBoundary
                    fallback={
                        <UnknownError error={"Could not load home page!"} />
                    }
                >
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <div>
                                    <Navbar />
                                    <main>
                                        <Outlet />
                                    </main>
                                    <Footer />
                                </div>
                            }
                        >
                            <Route path="/" element={<Home />} />
                            <Route path="/guilds" element={<Dashboard />} />
                            <Route
                                path="/dashboard/overview"
                                element={
                                    <div className="overview-page">
                                        <GuildManageSidebar />
                                        <Overview />
                                    </div>
                                }
                            />
                            <Route
                                path="/dashboard/logging"
                                element={
                                    <div className="logging-page">
                                        <GuildManageSidebar />
                                        <Logging />
                                    </div>
                                }
                            />
                            <Route
                                path="/dashboard/automoderation"
                                element={
                                    <div className="automoderation-page">
                                        <GuildManageSidebar />
                                        <Automoderation />
                                    </div>
                                }
                            />
                            <Route
                                path="/dashboard/moderation"
                                element={
                                    <div className="moderation-page">
                                        <GuildManageSidebar />
                                        <Moderation />
                                    </div>
                                }
                            />
                        </Route>
                        <Route path="/notfound" element={<NotFound />} />
                        <Route
                            path="/unauthorized"
                            element={<Unauthorized />}
                        />
                        <Route
                            path="/unknownerror"
                            element={<UnknownError error="" />}
                        />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </ErrorBoundary>
            </BrowserRouter>
        </QueryClientProvider>
    );
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(<App />);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
