import { Option } from "./ReactSelect";

export type SettingsType = "spam" | "scam" | "invite" | "dox" | "ai" | "raid";

/*
 * Object for JSON formatted settings config
 */
export interface SettingsConfigJson {
    guildid?: string;
    setting: SettingsType;
    config: Config;
}

interface Config {
    isEnabled: boolean;
    action: string;
    slowmodeInterval: string;
}

export const AutoActionOptions: Option[] = [
    { value: "0", label: "Delete" },
    { value: "1", label: "Warn" },
    { value: "2", label: "Timeout 30m" },
    { value: "3", label: "Timeout 6h" },
    { value: "4", label: "Timeout indefinite" },
    { value: "5", label: "Kick" },
    { value: "6", label: "Ban 7d" },
    { value: "7", label: "Ban" },
];

/*
 * Dropdown options for guild setting changes (for spam)
 */
export const SpamOptions: Option[] = [
    { value: "2", label: "Timeout 30m" },
    { value: "3", label: "Timeout 6h" },
    { value: "4", label: "Timeout indefinite" },
    { value: "5", label: "Kick" },
    { value: "6", label: "Ban 7d" },
    { value: "7", label: "Ban" },
];

/*
 * Dropdown options for slowmode interval
 */
export const Times: Option[] = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
];
