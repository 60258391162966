import "../styles/desktop/logging.css";
import "../styles/mobile/logging.css";
import { SelectChannelCard } from "../comps/SelectChannelCard";

/** The logging page */
export function Logging() {
    return (
        <div className="logging">
            <h1>Logging</h1>
            <div className="selects">
                <SelectChannelCard
                    name="Modlog Channel"
                    description="TED will basically be useless if you do not setup a
                        modlog channel. Modlogs will be sent to the provided
                        channel (e.g. warns, mutes, automod actions, etc.)"
                    endpoint="modlog"
                    channelOption="modlog"
                />
                <SelectChannelCard
                    name="Events Log Channel"
                    description="Enables audit logs for the provided channel; the bot
                        will send all guild and user events to this channel."
                    endpoint="eventlog"
                    channelOption="eventlog"
                />
            </div>
        </div>
    );
}
