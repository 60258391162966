export interface Censor {
    guildid?: string;
    term: string;
    action: number;
}

export interface BulkCensor {
    guildid?: string;
    terms: string[];
    action?: number;
}

export async function getCensors(guildid: string): Promise<Censor[]> {
    return fetch(`https://api.liege.dev/censor?guildid=${guildid}`, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => response.json())
        .catch((e) => {});
}

export async function insertCensors(censors: BulkCensor): Promise<Censor> {
    return fetch("https://api.liege.dev/censor", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(censors),
    }).then((response) => response.json());
}

export async function removeCensors(censors: BulkCensor): Promise<void> {
    return fetch("https://api.liege.dev/censor", {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(censors),
    }).then((response) => response.json());
}
