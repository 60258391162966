import React, { useState } from "react";
import "../../styles/desktop/moderation/modals/modal.css";
import "../../styles/mobile/moderation/modals/modal.css";
import ReactSelect from "react-select";
import xImage from "../../images/x.svg";
import { AutoActionOptions } from "../../models/Settings";
import { customStyles, Option } from "../../models/ReactSelect";
import "../../styles/desktop/moderation/modals/regexModals.css";
import { deleteRegex, insertRegex, Regex } from "../../models/Regex";
import { getGuildIdFromUrl } from "../../models/Guild";

export interface AddRegexModalProps {
    open: boolean;
    onClose: () => void;
    refresh: () => void;
}

export function AddRegexModal({ open, onClose, refresh }: AddRegexModalProps) {
    const [text, setText] = useState<string>("");
    const [action, setAction] = useState<number>(0);

    const handleTextChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setText(event.target.value);
    };

    return open ? (
        <div>
            <div className="modal-parent">
                <div className="modal space-y-4">
                    <div className="modal-header">
                        <h1>Add regex</h1>
                        <img src={xImage} alt="Close" onClick={onClose} />
                    </div>
                    <div className="textbox">
                        <span className="char-count">{`${text.length}/2000`}</span>
                        <textarea
                            className="regex-input"
                            value={text}
                            onChange={handleTextChange}
                            maxLength={2000}
                            placeholder="Regex statement..."
                        />
                    </div>
                    <ReactSelect
                        options={AutoActionOptions}
                        styles={customStyles}
                        defaultValue={null}
                        isClearable={true}
                        onChange={(selectedOption) => {
                            setAction(
                                AutoActionOptions.findIndex(
                                    (option) =>
                                        option.value === selectedOption!.value
                                )
                            );
                        }}
                    />
                    <button
                        type="submit"
                        id="submit"
                        onClick={() => {
                            insertRegex({
                                guildid: getGuildIdFromUrl(),
                                statement: text,
                                action: action,
                            });
                            refresh();
                            onClose();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    ) : null;
}

export interface RemoveRegexModalProps {
    open: boolean;
    onClose: () => void;
    statements: Regex[];
    refresh: () => void;
}

export function RemoveRegexModal({
    open,
    onClose,
    statements,
    refresh,
}: RemoveRegexModalProps) {
    const selectOptions: Option[] = statements.map((statement, index) => ({
        value: statement.statement,
        label: `Index: ${index}`,
    }));

    const [selectedOption, setSelectedOption] = useState<string>();

    const regexsExist = statements.length > 0;

    return open ? (
        <div>
            <div className="modal-parent">
                <div className="modal space-y-4">
                    <div className="modal-header">
                        <h1>Remove regex</h1>
                        <img src={xImage} alt="Close" onClick={onClose} />
                    </div>
                    {regexsExist ? (
                        <>
                            <ReactSelect
                                options={selectOptions}
                                styles={customStyles}
                                isClearable={true}
                                placeholder="Select..."
                                onChange={(selectedOption) => {
                                    setSelectedOption(selectedOption?.value);
                                }}
                            />
                            <button
                                type="submit"
                                id="submit"
                                onClick={() => {
                                    deleteRegex({
                                        guildid: getGuildIdFromUrl(),
                                        statement: selectedOption!,
                                    });
                                    refresh();
                                    onClose();
                                }}
                            >
                                Submit
                            </button>
                        </>
                    ) : (
                        <>
                            <p>There are no regex statements to remove!</p>
                            <button
                                id="submit"
                                style={{ backgroundColor: "#36393f" }}
                                onClick={onClose}
                            >
                                Close
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    ) : null;
}
