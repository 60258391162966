import React, { useEffect, useState } from "react";
import { SettingsConfigCard } from "../comps/ConfigCard";
import "../styles/desktop/automoderation.css";
import "../styles/mobile/automoderation.css";
import "../styles/desktop/toggleswitch.css";
import { getSetting, getSettings, setSetting } from "../models/GuildSettings";
import { getGuildIdFromUrl } from "../models/Guild";
import { SettingsConfigJson, SettingsType } from "../models/Settings";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const cards = [
    {
        type: "spam",
        header: "Spam detection",
        description:
            "TED is able to quickly count the amount of messages a user sends and use that information to detect spam and raids. Not only does TED count these messages, but it will only punish users based on their recent activity.",
    },
    {
        type: "raid",
        header: "Raid detection",
        description:
            "This feature is in a beta phase and there may be false positives. Please report false positives on our support server. This feature currently only works on guilds with over 1000 members.",
    },
    {
        type: "invite",
        header: "Invite detection",
        description:
            "TED will automatically detect and delete messages that contain NSFW content. This feature is disabled by default.",
    },
    {
        type: "dox",
        header: "DOX detection",
        description:
            "TED will take action when content that may DOX a user is detected. The provided action will be taken and the provided slowmode (optional) will be set in the channel.",
    },
    {
        type: "scam",
        header: "Scam detection",
        description:
            "Messages that contain suspicious links are automatically deleted. TED has to be at least 80% confident for the message to be automatically deleted. For messages that TED is less confident in (70-79%), a message will be sent in the guilds modlog channel with the option to delete it.",
    },
    {
        type: "ai",
        header: "AI moderation",
        description:
            "TED uses OpenAI's moderation API endpoint to identify content that falls into the categories listed above. While this endpoint is meant to serve as 'a tool you can use to check whether content complies with OpenAI's usage policies,' we have found it to be useful in identifying harmful content on Discord.",
    },
];

export function Automoderation() {
    const guildid = getGuildIdFromUrl();
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: ["settings"],
        queryFn: () => getSettings(guildid),
    });

    const updateSetting = () => {
        queryClient.invalidateQueries({
            queryKey: ["settings"],
        });
    };

    return (
        <div className="automoderation">
            <h1>AutoModeration</h1>
            <div className="automods">
                {cards.map((card, index) => (
                    <SettingsConfigCard
                        key={index}
                        onChange={() => updateSetting()}
                        settingsQuery={query.data?.find?.(
                            (setting) => setting.setting === card.type
                        )}
                        settingType={card.type as SettingsType}
                        header={card.header}
                        description={card.description}
                        isEnabled={card.type === "raid"}
                    />
                ))}
            </div>
        </div>
    );
}
