import { useEffect, useState } from "react";
import refresh from "../../images/refresh.svg";
import { getGuildIdFromUrl } from "../../models/Guild";
import { Censor, getCensors } from "../../models/Censor";
import "../../styles/desktop/moderation/cards/censorCard.css";
import "../../styles/desktop/moderation/cards/card.css";
import "../../styles/mobile/moderation/cards/censorCard.css";
import "../../styles/mobile/moderation/cards/card.css";
import { AddCensorsModal, RemoveCensorsModal } from "../modals/CensorModals";
import { useQuery } from "@tanstack/react-query";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export function CensorCard() {
    const guildid = getGuildIdFromUrl();

    const [addModalOpen, setAddModalOpen] = useState(false);
    const [removeModalOpen, setRemoveModalOpen] = useState(false);

    const query = useQuery({
        queryKey: ["censors"],
        queryFn: getCensors.bind(null, guildid),
    });

    if (query.isLoading || !query.data) {
        return (
            <SkeletonTheme baseColor="#0f0f11" highlightColor="#222">
                <Skeleton className="card-skeleton" />
            </SkeletonTheme>
        );
    }
    const censors = query.data as Censor[];

    const handleRefresh = () => {
        query.refetch();
    };

    return (
        <div className="card">
            <div className="card-header space-x-8">
                <h2>Censored words & phrases</h2>
                <img
                    src={refresh}
                    height="25px"
                    width="25px"
                    onClick={handleRefresh}
                />
            </div>
            {censors.length !== 0 ? (
                <div className="censors space-x-2">
                    {censors.map((censor) => (
                        <div className="censor" key={censor.term}>
                            <p>{censor.term}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="censors">
                    <span>No censored words or phrases</span>
                </div>
            )}
            <div className="card-buttons space-x-1">
                <button
                    className="card-button"
                    id="add"
                    onClick={() => setAddModalOpen(true)}
                >
                    Add words
                </button>
                <button
                    className="card-button"
                    id="remove"
                    onClick={() => setRemoveModalOpen(true)}
                >
                    Remove words
                </button>
                {addModalOpen ? (
                    <AddCensorsModal
                        open={addModalOpen}
                        onClose={() => setAddModalOpen(false)}
                        refresh={handleRefresh}
                    />
                ) : null}
                {removeModalOpen && censors ? (
                    <RemoveCensorsModal
                        open={removeModalOpen}
                        onClose={() => setRemoveModalOpen(false)}
                        censors={censors}
                        refresh={handleRefresh}
                    />
                ) : null}
            </div>
        </div>
    );
}
