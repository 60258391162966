import React, { useState } from "react";
import "../../styles/desktop/moderation/modals/modal.css";
import ReactSelect from "react-select";
import xImage from "../../images/x.svg";
import { AutoActionOptions } from "../../models/Settings";
import { customStyles } from "../../models/ReactSelect";
import "../../styles/desktop/moderation/modals/shortcutModals.css";
import {
    ReasonShortcut,
    deleteReasonShortcut,
    insertReasonShortcut,
} from "../../models/ReasonShortcut";
import { getGuildIdFromUrl } from "../../models/Guild";

export interface AddShortcutsModalProps {
    open: boolean;
    onClose: () => void;
    refresh: () => void;
}

export function AddShortcutsModal({
    open,
    onClose,
    refresh,
}: AddShortcutsModalProps) {
    const [shortText, setShortText] = React.useState<string>("");
    const [longText, setLongText] = React.useState<string>("");
    const maxLength = 512;

    const handleShortTextChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setShortText(event.target.value);
    };

    const handleLongTextChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setLongText(event.target.value);
    };

    return open ? (
        <div>
            <div className="modal-parent">
                <div className="modal space-y-4">
                    <div className="modal-header">
                        <h1>Add Shortcut</h1>
                        <img src={xImage} alt="Close" onClick={onClose} />
                    </div>
                    <input
                        className="short-input"
                        value={shortText}
                        onChange={handleShortTextChange}
                        maxLength={50}
                        placeholder="Short reason..."
                    />
                    <div className="textbox">
                        <textarea
                            className="reason-input"
                            value={longText}
                            onChange={handleLongTextChange}
                            maxLength={maxLength}
                            placeholder="Detailed reason..."
                        />
                        <span className="char-count">{`${longText.length}/${maxLength}`}</span>
                    </div>
                    <button
                        type="submit"
                        id="submit"
                        onClick={() => {
                            insertReasonShortcut({
                                guildid: getGuildIdFromUrl(),
                                shortcut: shortText,
                                reason: longText,
                            });
                            refresh();
                            onClose();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    ) : null;
}

export interface RemoveShortcutsModalProps {
    open: boolean;
    onClose: () => void;
    refresh: () => void;
    shortcuts: ReasonShortcut[];
}

export function RemoveShortcutsModal({
    open,
    onClose,
    refresh,
    shortcuts,
}: RemoveShortcutsModalProps) {
    const [selected, setSelected] = useState<string>("");

    const shortcutsExists = shortcuts.length > 0;

    return open ? (
        <div>
            <div className="modal-parent">
                <div className="modal space-y-4">
                    <div className="modal-header">
                        <h1>Remove Shortcut</h1>
                        <img src={xImage} alt="Close" onClick={onClose} />
                    </div>
                    {shortcutsExists ? (
                        <>
                            <ReactSelect
                                options={shortcuts.map((shortcut) => ({
                                    value: shortcut.shortcut,
                                    label: shortcut.shortcut,
                                }))}
                                styles={customStyles}
                                isClearable={true}
                                closeMenuOnSelect={false}
                                placeholder="Select..."
                                onChange={(selected) => {
                                    setSelected(selected!.value);
                                }}
                            />
                            <button
                                type="submit"
                                id="submit"
                                onClick={() => {
                                    deleteReasonShortcut({
                                        guildid: getGuildIdFromUrl(),
                                        shortcut: selected,
                                    });
                                    refresh();
                                    onClose();
                                }}
                            >
                                Submit
                            </button>
                        </>
                    ) : (
                        <>
                            <p>There are no shortcuts to remove!</p>
                            <button
                                id="submit"
                                style={{ backgroundColor: "#36393f" }}
                                onClick={onClose}
                            >
                                Close
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    ) : null;
}
