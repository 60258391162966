export interface Stats {
    guildCount: number;
    userCount: number;
    actionCount: number;
}

export async function getStats(): Promise<Stats> {
    return (await (
        await fetch(`https://api.liege.dev/bot/stats`, {
            method: "GET",
            credentials: "include",
        })
    ).json()) as Stats;
}
