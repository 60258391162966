import { useEffect, useState } from "react";
import refresh from "../../images/refresh.svg";
import { getGuildIdFromUrl } from "../../models/Guild";
import "../../styles/desktop/moderation/cards/card.css";
import "../../styles/desktop/moderation/cards/regexCard.css";
import "../../styles/mobile/moderation/cards/card.css";
import "../../styles/mobile/moderation/cards/regexCard.css";
import { Regex, getRegexs } from "../../models/Regex";
import { AutoAction } from "../../models/AutoAction";
import { AddRegexModal, RemoveRegexModal } from "../modals/RegexModals";
import { useQuery } from "@tanstack/react-query";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export function RegexCard() {
    const guildid = getGuildIdFromUrl();

    const [addModalOpen, setAddModalOpen] = useState(false);
    const [removeModalOpen, setRemoveModalOpen] = useState(false);

    const query = useQuery({
        queryKey: ["regexs"],
        queryFn: getRegexs.bind(null, guildid),
    });

    if (query.isLoading || !query.data) {
        return (
            <SkeletonTheme baseColor="#0f0f11" highlightColor="#222">
                <Skeleton className="card-skeleton" />
            </SkeletonTheme>
        );
    }
    const regexs = query.data as Regex[];

    const handleRefresh = () => {
        query.refetch();
    };

    return (
        <div className="card">
            <div className="card-header space-x-8">
                <h2>Regex</h2>
                <img
                    src={refresh}
                    height="25px"
                    width="25px"
                    onClick={handleRefresh}
                />
            </div>
            <div className="regexs space-x-2">
                <ul className="card-list">
                    {regexs.length !== 0 ? (
                        regexs.map((regex, index) => (
                            <li className="regex">
                                <span className="regex-action">
                                    Action: {AutoAction[regex.action!]}
                                    &nbsp;&nbsp;•&nbsp;&nbsp;Index: {index}
                                </span>
                                <br />
                                {regex.statement}
                            </li>
                        ))
                    ) : (
                        <span>No regex statements</span>
                    )}
                </ul>
            </div>
            <div className="card-buttons space-x-1">
                <button
                    className="card-button"
                    id="add"
                    onClick={() => setAddModalOpen(true)}
                >
                    Add regex
                </button>
                <button
                    className="card-button"
                    id="remove"
                    onClick={() => setRemoveModalOpen(true)}
                >
                    Remove regex
                </button>
                {addModalOpen ? (
                    <AddRegexModal
                        open={addModalOpen}
                        onClose={() => setAddModalOpen(false)}
                        refresh={handleRefresh}
                    />
                ) : null}
                {removeModalOpen ? (
                    <RemoveRegexModal
                        open={removeModalOpen}
                        onClose={() => setRemoveModalOpen(false)}
                        statements={regexs!}
                        refresh={handleRefresh}
                    />
                ) : null}
            </div>
        </div>
    );
}
