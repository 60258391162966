/** Properties for the button component */
export interface ButtonProps {
    text: string;
    link: string;
    color: string;
}

/** Button component; typically used in the section component, but can be used globally */
export function Button(props: ButtonProps) {
    const style = {
        backgroundColor: props.color,
    };

    return (
        <a href={props.link} target="_blank">
            <button className="sec-button" style={style}>
                {props.text}
            </button>
        </a>
    );
}
